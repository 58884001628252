
import {defineComponent, ref, watch} from "vue";
import DocService from "@/core/services/DocService";
import {GetSpaceId} from "@/core/composite/composite";

export default defineComponent({
  name: "Folder",
  props: {
    docId: {}
  },
  setup(props) {
    const folders = ref([])
    const load = (id) => {
      if (id) {
        DocService.folders(id).then(res => {
          folders.value = res
        })
      }
    }
    load(props.docId);
    watch(() => props.docId, cb => {
      load(cb)
    })
    return {
      ...GetSpaceId(),
      load,
      folders
    }
  }
})
