import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-row-fluid"
}
const _hoisted_2 = { class: "flex-column flex-row-fluid" }
const _hoisted_3 = {
  key: 0,
  class: "card shadow-sm card-docs flex-row-fluid"
}
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = {
  key: 0,
  class: "text-muted fs-5 ps-1"
}
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "me-9 my-1 float-end"
}
const _hoisted_9 = { class: "card-body" }
const _hoisted_10 = {
  key: 0,
  class: "flex-column flex-row-auto w-300px ms-lg-4"
}
const _hoisted_11 = {
  key: 0,
  class: "card-body"
}
const _hoisted_12 = {
  key: 0,
  class: "card-px text-center pt-15 pb-15"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EntityLoading = _resolveComponent("EntityLoading")!
  const _component_Docs = _resolveComponent("Docs")!
  const _component_FAQ = _resolveComponent("FAQ")!
  const _component_Folder = _resolveComponent("Folder")!
  const _component_File = _resolveComponent("File")!

  return (_ctx.isLoading)
    ? (_openBlock(), _createBlock(_component_EntityLoading, { key: 0 }))
    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
        (_ctx.data.id)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                (_ctx.data.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                      _createElementVNode("div", _hoisted_4, [
                        _createElementVNode("h3", _hoisted_5, [
                          _createTextVNode(_toDisplayString(_ctx.data.name) + " ", 1),
                          (_ctx.data.description)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.data.description), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _createElementVNode("div", _hoisted_7, [
                          (_ctx.data?.id)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_8))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("div", _hoisted_9, [
                        _createVNode(_component_Docs, {
                          type: "PAGE",
                          "parent-id": _ctx.data.id
                        }, null, 8, ["parent-id"])
                      ])
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.data.faq)
                  ? (_openBlock(), _createBlock(_component_FAQ, {
                      key: 1,
                      "doc-id": _ctx.data.id
                    }, null, 8, ["doc-id"]))
                  : _createCommentVNode("", true)
              ]),
              (_ctx.data.id)
                ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                    (_ctx.data.access.showFolder)
                      ? (_openBlock(), _createBlock(_component_Folder, {
                          key: 0,
                          "doc-id": _ctx.data.id
                        }, null, 8, ["doc-id"]))
                      : _createCommentVNode("", true),
                    (_ctx.data.access.showFile)
                      ? (_openBlock(), _createBlock(_component_File, {
                          key: 1,
                          "doc-id": _ctx.data.id,
                          "can-add-file": _ctx.data.access.canAddFile
                        }, null, 8, ["doc-id", "can-add-file"]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
              (!_ctx.isLoading)
                ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                    (_ctx.user.admin)
                      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                          _createElementVNode("a", {
                            href: "#",
                            class: "btn btn-primary er fs-6 px-8 py-4",
                            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.addHelp && _ctx.addHelp(...args)))
                          }, "Create Help Library")
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ], 64))
      ], 64))
}