
import {computed, defineComponent, onBeforeMount, onMounted, ref} from "vue";
import DocService from "@/core/services/DocService";
import router from "@/router";
import {GetSpaceId} from "@/core/composite/composite";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import store from "@/store";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import FAQ from "@/views/doc/FAQ.vue";
import Folder from "@/views/doc/Folder.vue";
import File from "@/views/doc/File.vue";
import Docs from "@/views/doc/Docs.vue";

export default defineComponent({
  name: "HelpLayout",
  components: {Docs, File, Folder, FAQ,  EntityLoading},
  setup() {

    const isLoading = ref(true);
    const data = ref<any>({});
    onMounted(() => {
      setCurrentPageBreadcrumbsWithParams("Help Center",
        [
          {link: false, router: '', text: 'Help Center'}
        ]
      )
    })

    const spaceId = GetSpaceId().spaceId
    onBeforeMount(async () => {

      await DocService.getLibraryHelp().then(res => {
        data.value = res;
        // if (res.id) {
        //   router.push('/help-center/' + res.id)
        // }
      }).finally(() => {
        isLoading.value = false;
      })
    })
    const user = computed(() => store.getters.currentUser)
    return {
      isLoading,
      user,
      spaceId,
      data,
    }
  },
  methods: {
    addHelp() {
      DocService.createLibraryHelp().then(res => {
        router.push('/help-center/' + res.id)
      })
    }
  }
})
