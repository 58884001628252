
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {GetSpaceId, LoadFilterObjects} from "@/core/composite/composite";
import {Actions} from "@/store/enums/StoreEnums";
import Avatar from "@/components/base/common/Avatar.vue";
import PageStatus from "@/views/doc/PageStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "Docs",
  components: {DateTimeFormat, PageStatus, Avatar},
  props: {
    parentId: {},
    type: {}
  },
  setup(props) {
    const refId = ref<any>(props.parentId);
    const filterObject = ref({parentId: refId.value, type: 'PAGE'});
    const listPage = computed(() => store.state.DocModule.pages)
    return {
      listPage,
      ...GetSpaceId(),
      ...LoadFilterObjects(Actions.LOAD_PAGES, filterObject.value, ['createdBy'], 'sort:order:asc'),
    }
  }
})
